import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";

const EditUser = () => {
  const { id } = useParams(); // Get the user ID from the route params
  const navigate = useNavigate(); // For navigating back after editing
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    // Fetch user details based on the userId
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get(`/users/${id}`);
        setUser(response.data); // Populate form with user data
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch user details.");
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await axiosInstance.put(`/api/users/${id}`, user); // PUT request to update user
      setSaving(false);
      navigate.push(`/users/${id}`); // Redirect to view user page after save
    } catch (error) {
      setError("Failed to update user.");
      setSaving(false);
    }
  };

  if (loading) {
    return <div>Loading user details...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Edit User</h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label className="block text-gray-700">Username</label>
          <input
            type="text"
            name="username"
            value={user.username}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={user.email}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Phone</label>
          <input
            type="text"
            name="phone"
            value={user.phone}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Country</label>
          <input
            type="text"
            name="country"
            value={user.country}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Credit Balance</label>
          <input
            type="number"
            name="credit_balance"
            value={user.credit_balance}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Role</label>
          <select
            name="role"
            value={user.role}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          >
            <option value="CUSTOMER">Customer</option>
            <option value="ADMIN">Admin</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Status</label>
          <select
            name="status"
            value={user.status}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          >
            <option value="ACTIVE">Active</option>
            <option value="DEACTIVE">Deactive</option>
          </select>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          disabled={saving}
        >
          {saving ? "Saving..." : "Save Changes"}
        </button>

        <button
          type="button"
          className="ml-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          onClick={() => navigate.push(`/admin/users/${id}`)}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default EditUser;
