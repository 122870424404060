import React, { useState } from "react";
import PayPalButton from "../components/PayPalButton";

const BuyCredits = () => {
  const [credits, setCredits] = useState(0);
  const [message, setMessage] = useState("");

  const handlePurchase = (e) => {
    e.preventDefault();
    if (credits > 0) {
      setMessage(`You have successfully purchased ${credits} credits!`);
    } else {
      setMessage("Please select a valid amount of credits.");
    }
  };

  return (
    <div className="max-w-md mx-auto px-4 py-8 bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-200 rounded">
      <h2 className="text-2xl mb-4">Buy Credits</h2>
      <form onSubmit={handlePurchase} className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="credits" className="mb-2">
            Number of Credits:
          </label>
          <input
            type="number"
            id="credits"
            value={credits}
            onChange={(e) => setCredits(e.target.value)}
            min="0"
            className="border border-gray-300 dark:border-gray-700 p-2 w-full bg-gray-50 dark:bg-gray-700 dark:text-gray-200"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded w-full sm:w-auto hover:bg-blue-700 dark:bg-gray-700 dark:hover:bg-gray-600"
        >
          Buy Credits
        </button>
        <PayPalButton />
      </form>
      {message && <p className="mt-4 text-lg">{message}</p>}
    </div>
  );
};

export default BuyCredits;
