import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import Loader from "./Loader";

function ChooseSolution({ setShowModal, setSelectedOptions }) {
  const [solutions, setSolutions] = useState([]);
  const [radioValue, setRadioValue] = useState("");
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [dtcValue, setDtcValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get("/solutions")
      .then((response) => {
        setLoading(false);
        setSolutions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching solutions:", error);
      });
  }, []);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (checkboxValues.includes(value)) {
      setCheckboxValues(checkboxValues.filter((item) => item !== value));
    } else {
      setCheckboxValues([...checkboxValues, value]);
    }
  };

  const handleSaveChanges = () => {
    const allSelections = [...checkboxValues, radioValue];
    if (dtcValue.trim() !== "") {
      allSelections.push(`DTC: ${dtcValue}`);
    }
    setSelectedOptions(allSelections);
    setShowModal(false);
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 flex items-center justify-center">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center dark:bg-gray-900 dark:bg-opacity-75">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-xl shadow-lg transition-transform duration-300 ease-in-out">
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 p-4 bg-gray-300 dark:bg-gray-700 rounded-t">
          Select Options
        </h3>

        <div className="h-auto max-h-[80vh] overflow-y-auto px-6 mt-4">
          {/* Radio Buttons styled as 50x50 Tiles */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            {solutions
              .filter((solution) => solution.type === "TUNING")
              .map((solution) => (
                <label
                  className="flex flex-col items-center cursor-pointer"
                  key={solution.id}
                >
                  <input
                    type="radio"
                    name="option"
                    value={solution.name}
                    onChange={handleRadioChange}
                    checked={radioValue === solution.name}
                    className="sr-only"
                  />
                  <div
                    className={`w-24 h-24 flex justify-center items-center rounded-lg border-2 ${
                      radioValue === solution.name
                        ? "border-green-500 bg-gray-100 dark:bg-gray-500"
                        : "border-gray-400 bg-gray-300 dark:bg-gray-200"
                    } transition-colors duration-300 ease-in-out`}
                  >
                    {/* Icon Placeholder */}
                    <img className="rounded-lg" src={solution.image_url} />
                  </div>
                  <span className="text-gray-700 dark:text-gray-300 text-sm mt-2">
                    {solution.name}
                  </span>
                </label>
              ))}
          </div>

          {/* Styled Checkboxes as 50x50 Tiles */}
          <div className="mb-6">
            <label className="block font-semibold text-gray-900 dark:text-gray-100 mb-2">
              Additional Options
            </label>
            <div className="grid grid-cols-4 gap-4">
              {solutions
                .filter((solution) => solution.type === "OPTION")
                .map((solution) => (
                  <label
                    className="flex flex-col items-center cursor-pointer"
                    key={solution.id}
                  >
                    <input
                      type="checkbox"
                      value={solution.name}
                      onChange={handleCheckboxChange}
                      checked={checkboxValues.includes(solution.name)}
                      className="sr-only"
                    />
                    <div
                      className={`w-24 h-24 flex justify-center items-center rounded-lg border-2 ${
                        checkboxValues.includes(solution.name)
                          ? "border-green-500 bg-gray-700 dark:bg-gray-600"
                          : "border-gray-400 bg-gray-900 dark:bg-gray-700"
                      } transition-colors duration-300 ease-in-out`}
                    >
                      {/* Icon Placeholder */}
                      <img className="rounded-lg" src={solution.image_url} />
                    </div>
                    <span className="text-gray-700 dark:text-gray-300 text-sm mt-2 text-center">
                      {solution.name}
                    </span>
                  </label>
                ))}
            </div>
          </div>

          {/* DTC Input */}
          <div className="mb-6">
            <label className="block font-semibold text-gray-900 dark:text-gray-100">
              Write DTC
            </label>
            <input
              type="text"
              value={dtcValue}
              onChange={(e) => setDtcValue(e.target.value)}
              className="border border-gray-300 dark:border-gray-600 w-full p-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-300 transition-shadow duration-300 ease-in-out bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
              placeholder="Enter DTC (P, U) etc..."
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4 p-4 bg-gray-200 dark:bg-gray-700 rounded-b">
          <button
            type="button"
            className="bg-gray-400 dark:bg-gray-600 hover:bg-gray-500 dark:hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 ease-in-out"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="bg-blue-500 dark:bg-blue-600 hover:bg-blue-600 dark:hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 ease-in-out"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChooseSolution;
