import React, { useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";

const PayPalButton = ({ userId }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=YOUR_PRODUCTION_CLIENT_ID&currency=USD`;
    script.addEventListener("load", () => {
      if (window.paypal) {
        window.paypal
          .Buttons({
            style: {
              layout: "vertical",
              color: "blue",
              shape: "pill",
              label: "paypal",
            },
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: "10.00",
                    },
                  },
                ],
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                // Notify the backend to update the user's balance
                axiosInstance
                  .post("/api/paypal/notify", {
                    orderID: data.orderID,
                    userID: userId,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      alert(
                        `Transaction completed successfully for ${details.payer.name.given_name}`
                      );
                    } else {
                      alert("Error updating user credits");
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    alert("An error occurred while updating user credits");
                  });
              });
            },
            onError: (err) => {
              console.error(err);
              alert("An error occurred with the payment");
            },
          })
          .render("#paypal-button-container");
      }
    });
    document.body.appendChild(script);
  }, []);

  return (
    <div className="flex flex-col items-center space-y-4 mt-8">
      <h2 className="text-2xl font-semibold text-gray-800">Buy Credits</h2>
      <p className="text-gray-600">
        Purchase credits to enjoy our premium features!
      </p>
      <div
        id="paypal-button-container"
        className="w-full max-w-sm mx-auto shadow-lg p-4 rounded-lg bg-white border border-gray-200"
      ></div>
    </div>
  );
};

export default PayPalButton;
