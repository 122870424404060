import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getStatusBadgeClass, truncateText } from "../utils/Utils";
import Loader from "../components/Loader";
import { AuthContext } from "../context/AuthContext";

const MyFiles = () => {
  const { user } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileToDelete, setFileToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/users/${user.id}/files`);
        setFiles(response.data);
      } catch (err) {
        setError("Failed to fetch files");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  // Open delete confirmation modal
  const handleDeleteClick = (file) => {
    setFileToDelete(file);
  };

  // Perform delete action
  const handleDeleteConfirm = () => {
    setFiles(files.filter((file) => file.id !== fileToDelete.id));
    setFileToDelete(null);
  };

  // Close the modal without deleting
  const handleCloseModal = () => {
    setFileToDelete(null);
  };

  // Filtered files based on search term
  const filteredFiles = files.filter(
    (file) =>
      file.file_id
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 dark:bg-gray-900 dark:text-white">
      <h1 className="text-2xl font-bold mb-4">All Files</h1>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          className="border border-gray-300 p-2 w-full rounded-md dark:border-gray-600 dark:bg-gray-800 dark:text-white"
          placeholder="Search by file id, file name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Table for displaying files */}
      <table className="min-w-full table-auto bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700">
        <thead>
          <tr className="bg-gray-100 dark:bg-gray-700">
            <th className="px-4 py-2 border-b text-left dark:border-gray-600">
              File ID
            </th>
            <th className="px-4 py-2 border-b text-left dark:border-gray-600">
              Vehicle
            </th>
            <th className="px-4 py-2 border-b text-left dark:border-gray-600">
              File Name
            </th>
            <th className="px-4 py-2 border-b text-left dark:border-gray-600">
              Credit
            </th>
            <th className="px-4 py-2 border-b text-left dark:border-gray-600">
              Price Status
            </th>
            <th className="px-4 py-2 border-b text-left dark:border-gray-600">
              Status
            </th>
            <th className="px-4 py-2 border-b text-left text-center align-middle dark:border-gray-600">
              View
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles.map((file) => (
            <tr
              key={file.id}
              className="hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <td className="px-4 py-2 border-b dark:border-gray-600">
                {file.file_id}
              </td>
              <td className="px-4 py-2 border-b dark:border-gray-600">
                {file.brand}
              </td>
              <td className="px-4 py-2 border-b dark:border-gray-600">
                {truncateText(file.file_name, 35)}
              </td>
              <td className="px-4 py-2 border-b dark:border-gray-600">
                {file.price}
              </td>
              <td className="px-4 py-2 border-b dark:border-gray-600">
                {file.price_status}
              </td>
              <td className="px-4 py-2 border-b dark:border-gray-600">
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                    file.status
                  )}`}
                >
                  {file.status}
                </span>
              </td>
              <td className="px-4 py-2 border-b text-center align-middle dark:border-gray-600">
                <button className="text-blue-500 dark:text-blue-400 py-1 rounded">
                  <Link to={`/view-file/${file.id}`}>
                    <EyeIcon className="h-5 w-5 mr-1" />
                  </Link>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MyFiles;
