import React, { useState } from "react";
import { FiMessageSquare, FiX } from "react-icons/fi";

const SupportChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const toggleChatBox = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      // You can handle sending the message to the admin here.
      alert("Message sent to admin: " + message);
      setMessage(""); // Reset the message input
    }
  };

  return (
    <div className="fixed bottom-5 right-5 z-50">
      {/* Chat Icon Button */}
      {!isOpen && (
        <button
          onClick={toggleChatBox}
          className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-700"
        >
          <FiMessageSquare size={24} />
        </button>
      )}

      {/* Chat Box */}
      {isOpen && (
        <div className="w-80 p-4 rounded-lg shadow-lg bg-white dark:bg-gray-800 dark:border-gray-700 border border-gray-200">
          <div className="flex justify-between items-center mb-3">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
              Support Chat
            </h4>
            <button
              onClick={toggleChatBox}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            >
              <FiX size={20} />
            </button>
          </div>

          <div className="mb-3">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="3"
              className="w-full p-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-100 rounded-md focus:outline-none focus:border-blue-500 dark:focus:border-blue-400"
              placeholder="Type your message here..."
            />
          </div>

          <button
            onClick={handleSendMessage}
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
          >
            Send Message
          </button>
        </div>
      )}
    </div>
  );
};

export default SupportChatBox;
