import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { EyeIcon } from "@heroicons/react/24/outline";
import { getStatusBadgeClass, truncateText } from "../utils/Utils";
import Loader from "../components/Loader";
import { AuthContext } from "../context/AuthContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileToDelete, setFileToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/dashboard/${user.id}`);
        setDashboard(response.data);
        setFiles(response.data.files);
      } catch (err) {
        if (err.status === 403) {
          navigate("/login");
        }
        setError("Failed to fetch files");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  // Open delete confirmation modal
  const handleDeleteClick = (file) => {
    setFileToDelete(file);
  };

  // Perform delete action
  const handleDeleteConfirm = () => {
    setFiles(files.filter((file) => file.id !== fileToDelete.id));
    setFileToDelete(null);
  };

  const handleCloseModal = () => {
    setFileToDelete(null);
  };

  const filteredFiles = files.filter(
    (file) =>
      file.file_id
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-6 dark:bg-gray-900 dark:text-gray-100">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="flex flex-wrap -mx-6">
        <div className="w-full px-6 sm:w-1/2 xl:w-1/3">
          <div className="flex items-center px-5 py-6 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <div className="p-3 bg-green-600 text-white bg-opacity-75 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </div>

            <div className="mx-5">
              <h4 className="text-2xl font-semibold text-gray-700 dark:text-gray-100">
                {loading ? "..." : dashboard && dashboard.completed}
              </h4>
              <div className="text-gray-500 dark:text-gray-400">Completed</div>
            </div>
          </div>
        </div>

        <div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
          <div className="flex items-center px-5 py-6 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <div className="p-3 bg-pink-600 text-white bg-opacity-75 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                />
              </svg>
            </div>

            <div className="mx-5">
              <h4 className="text-2xl font-semibold text-gray-700 dark:text-gray-100">
                {loading ? "..." : dashboard && dashboard.refunded}
              </h4>
              <div className="text-gray-500 dark:text-gray-400">Refunded</div>
            </div>
          </div>
        </div>

        <div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
          <div className="flex items-center px-5 py-6 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <div className="p-3 bg-red-600 text-white bg-opacity-75 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="mx-5">
              <h4 className="text-2xl font-semibold text-gray-700 dark:text-gray-100">
                {loading ? "..." : dashboard && dashboard.canceled}
              </h4>
              <div className="text-gray-500 dark:text-gray-400">Canceled</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8"></div>

      {loading ? (
        <div className="container mx-auto p-6">
          <Loader />
        </div>
      ) : (
        <>
          {/* Search Bar */}
          <div className="mb-4">
            <input
              type="text"
              className="border border-gray-300 dark:border-gray-700 p-2 w-full rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Search by file id, file name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <h2 className=" font-bold mb-4">Last Files</h2>

          {/* Table for displaying files */}
          <table className="min-w-full table-auto bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-700">
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                  File ID
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                  Vehicle
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                  File Name
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                  Credit
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                  Price Status
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left">
                  Status
                </th>
                <th className="px-4 py-2 border-b dark:border-gray-600 text-left text-center align-middle">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredFiles.map((file) => (
                <tr
                  key={file.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-4 py-2 border-b dark:border-gray-600">
                    {file.file_id}
                  </td>
                  <td className="px-4 py-2 border-b dark:border-gray-600">
                    {file.brand}
                  </td>
                  <td className="px-4 py-2 border-b dark:border-gray-600">
                    {truncateText(file.file_name, 35)}
                  </td>
                  <td className="px-4 py-2 border-b dark:border-gray-600">
                    {file.price}
                  </td>
                  <td className="px-4 py-2 border-b dark:border-gray-600">
                    {file.price_status}
                  </td>
                  <td className="px-4 py-2 border-b dark:border-gray-600">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                        file.status
                      )}`}
                    >
                      {file.status}
                    </span>
                  </td>
                  <td className="px-4 py-2 border-b dark:border-gray-600 text-center align-middle">
                    <button className="text-blue-500 py-1 rounded dark:text-blue-400">
                      <Link to={`/view-file/${file.id}`}>
                        <EyeIcon className="h-5 w-5 mr-1" />
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Dashboard;
