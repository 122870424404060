import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DownloadFileButton from "../../components/DownloadFileButton";
import DownloadModFileButton from "../../components/DownloadModFileButton";
import Loader from "../../components/Loader";
import UploadModFileButton from "../../components/UploadModFileButton";
import axiosInstance from "../../utils/axiosInstance";
import { getStatusBadgeClass } from "../../utils/Utils";
import SupportChatBox from "../SupportChatBox";
import FileHistory from "./FileHistory";

const ViewFile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/files/${id}`);
        setData(response.data);
      } catch (err) {
        setError("Failed to fetch files");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  if (loading)
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  const { file, vehicle } = data;

  return (
    <div className="p-6 min-h-screen">
      {/* Container for File Details and Vehicle Details */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* File Information Section */}
        <div className="bg-white p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">File Information</h2>
          <div className="space-y-2">
            <p>
              <strong>File ID:</strong> {file.file_id}
            </p>
            <p>
              <strong>File Name:</strong> {file.file_name}
            </p>
            <p>
              <strong>Username:</strong> {file.user_id}
            </p>
            <p>
              <strong>Price:</strong>{" "}
              <span className="text-yellow-300">CRS {file.price}</span>
            </p>
            <p>
              <strong>Solution Expected:</strong> {file.expected}
            </p>
            <p>
              <strong>Reading Device:</strong> {file.reading_device}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                  file.status
                )}`}
              >
                {file.status}
              </span>
            </p>
            <p>
              <strong>Uploaded:</strong> {file.created_at}
            </p>
            <p>
              <strong>Modified:</strong> {file.updated_at}
            </p>
          </div>
        </div>

        {/* Vehicle Information Section */}
        <div className="bg-white p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">Files</h2>
          <DownloadFileButton fileId={file.id} fileName={file.file_name} />

          <DownloadModFileButton
            fileId={file.id}
            fileName={file.mod_file_name}
            status={file.status}
          />

          <UploadModFileButton fileId={file.id} />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <div className="bg-white p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">Vehicle Information</h2>
          <div className="space-y-2">
            <p>
              <strong>Type:</strong> {vehicle.type}
            </p>
            <p>
              <strong>Brand:</strong> {vehicle.brand}
            </p>
            <p>
              <strong>Model:</strong> {vehicle.model}
            </p>
            <p>
              <strong>ECU:</strong> {vehicle.ecu}
            </p>
            <p>
              <strong>Generation:</strong> {vehicle.generation}
            </p>
            <p>
              <strong>Gear:</strong> {vehicle.gear}
            </p>
            <p>
              <strong>Petrol Type:</strong> {vehicle.petrol_type}
            </p>
          </div>
        </div>
        <div className="bg-white p-6 rounded-md shadow-sm">
          <FileHistory fileId={file.id} />
        </div>
      </div>
      <SupportChatBox />
    </div>
  );
};

export default ViewFile;
