import { Link, useLocation } from "react-router-dom";
import {
  ArrowUpTrayIcon,
  DocumentIcon,
  UserIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";

const getMatchedPage = (path) => {
  return (
    ["dashboard", "upload", "my-files", "buy-credits", "my-ticket"].find(
      (page) => path.includes(page)
    ) || ""
  );
};

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const [isSupportDropdownOpen, setIsSupportDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    getMatchedPage(location.pathname)
  );

  const toggleSupportDropdown = () => {
    setIsSupportDropdownOpen(!isSupportDropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    toggleSidebar();
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 w-64 bg-gray-900 dark:bg-gray-800 text-white dark:text-gray-200 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
    >
      <div className="flex justify-end md:hidden">
        <button
          onClick={toggleSidebar}
          className="text-white p-4 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <h2 className="text-lg font-bold mb-4 p-4">VDP Tuning Team</h2>
      <nav>
        <ul className="dark:mx-2">
          <li
            className={`${
              selectedItem === "dashboard"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/dashboard"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("dashboard")}
            >
              <HomeIcon className="w-5 h-5" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "upload"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/upload"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("upload")}
            >
              <ArrowUpTrayIcon className="w-5 h-5" />
              <span>Upload</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "my-files"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/my-files"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("my-files")}
            >
              <DocumentIcon className="w-5 h-5" />
              <span>My Files</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "buy-credits"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/buy-credits"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("buy-credits")}
            >
              <CurrencyDollarIcon className="w-5 h-5" />{" "}
              {/* Icon for Buy Credits */}
              <span>Buy Credits</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "my-ticket"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/my-ticket"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("my-ticket")}
            >
              <UserIcon className="w-5 h-5" /> {/* Icon for Support */}
              <span>Support</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
