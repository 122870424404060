import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { getStatusBadgeClass, truncateText } from "../../utils/Utils";
import Loader from "../../components/Loader";

const AllFiles = () => {
  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get("/files");
        setFiles(response.data);
      } catch (err) {
        setError("Failed to fetch files");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoadingToDelete(true);
      await axiosInstance.delete(`/files/${fileToDelete.id}`);
      setFiles(files.filter((file) => file.id !== fileToDelete.id));
      setShowDeleteModal(false);
      setFileToDelete(null);

      // Show notification after successful deletion
      setShowNotification(true);

      // Hide notification after 5 seconds
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      setLoadingToDelete(false);
    } catch (err) {
      setError("Failed to delete solution. Please try again.");
    }
  };

  // Close the modal without deleting
  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setFileToDelete(null);
  };

  // Filtered files based on search term
  const filteredFiles = files.filter(
    (file) =>
      file.file_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(file.file_id).includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">All Files</h1>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          className="border border-gray-300 p-2 w-full rounded-md"
          placeholder="Search by file id, file name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Table for displaying files */}
      <table className="min-w-full table-auto bg-white border border-gray-300 rounded-md">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border-b text-left">File ID</th>
            <th className="px-4 py-2 border-b text-left">Username</th>
            <th className="px-4 py-2 border-b text-left">Vehicle</th>
            <th className="px-4 py-2 border-b text-left">File Name</th>
            <th className="px-4 py-2 border-b text-left">Credit</th>
            <th className="px-4 py-2 border-b text-left">Price Status</th>
            <th className="px-4 py-2 border-b text-left">Status</th>
            <th className="px-4 py-2 border-b text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles.map((file) => (
            <tr key={file.id} className="hover:bg-gray-50">
              <td className="px-4 py-2 border-b">{file.file_id}</td>
              <td className="px-4 py-2 border-b">{file.username}</td>
              <td className="px-4 py-2 border-b">{file.brand}</td>
              <td className="px-4 py-2 border-b">
                {truncateText(file.file_name, 35)}
              </td>
              <td className="px-4 py-2 border-b">{file.price}</td>
              <td className="px-4 py-2 border-b">{file.price_status}</td>
              <td className="px-4 py-2 border-b">
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                    file.status
                  )}`}
                >
                  {file.status}
                </span>
              </td>
              <td className="px-4 py-2 border-b flex">
                <button className="text-blue-500  p-1 rounded mr-2 hover:bg-blue-600 hover:text-white flex items-center">
                  <Link to={`/admin/view-file/${file.id}`}>
                    <EyeIcon className="h-5 w-5" />
                  </Link>
                </button>
                <button
                  className="text-red-500 p-1 rounded hover:bg-red-600 hover:text-white flex items-center"
                  onClick={() => handleDeleteClick(file)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-lg font-semibold mb-4">Are you sure</h2>
            <p>
              Do you really want to delete the file ? <br />
              <strong>{truncateText(fileToDelete.file_name, 35)}</strong>{" "}
            </p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeleteConfirm}
                disabled={loadingToDelete}
              >
                {loadingToDelete ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Notification Popup */}
      {showNotification && (
        <div className="fixed top-16 right-4 p-4 bg-blue-100 text-blue-800 border border-blue-300 rounded-md shadow-xl transition-transform transform duration-500 ease-in-out translate-y-0 opacity-100">
          <div className="flex items-center">
            <svg
              className="w-6 h-6 mr-2 text-blue-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 2a10 10 0 110 20 10 10 0 010-20z"
              ></path>
            </svg>
            <p className="font-semibold">File deleted successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllFiles;
