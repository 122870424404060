import React, { useState, useEffect } from "react";
import {
  TrashIcon,
  PlusIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";

// Solution component
const Solution = () => {
  const [solutions, setSolutions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [solutionToDelete, setSolutionToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  // Fetch solutions from API
  useEffect(() => {
    fetchSolutions();
  }, []);

  const fetchSolutions = async () => {
    try {
      const response = await axiosInstance.get("/solutions");
      setSolutions(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  // Open delete confirmation modal
  const handleDeleteClick = (solution) => {
    setSolutionToDelete(solution);
    setShowDeleteModal(true);
  };

  // Perform delete action with DELETE request
  const handleDeleteConfirm = async () => {
    try {
      setButtonLoading(true);
      await axiosInstance.delete(`/solutions/${solutionToDelete.id}`);
      setSolutions(
        solutions.filter((solution) => solution.id !== solutionToDelete.id)
      );
      setShowDeleteModal(false);
      setButtonLoading(false);
      setSolutionToDelete(null);
      setShowNotification(true);
    } catch (err) {
      setError("Failed to delete solution. Please try again.");
    }
  };

  // Close the modal without deleting
  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setSolutionToDelete(null);
  };

  // Filter solutions based on search term
  const filteredSolutions = solutions.filter(
    (solution) =>
      solution.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      solution.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      solution.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="container mx-auto p-6">
      {/* Heading and Add Button */}

      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Solution List</h1>

        <div className="flex space-x-2">
          <Link
            to="/admin/add-solution"
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Add New Solution
          </Link>
        </div>
      </div>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          className="border border-gray-300 p-2 w-full rounded-md"
          placeholder="Search by name, code, or type..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table className="min-w-full table-auto bg-white border border-gray-300 rounded-md">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border-b text-left">Name</th>
            <th className="px-4 py-2 border-b text-left">Code</th>
            <th className="px-4 py-2 border-b text-left">Credits</th>
            <th className="px-4 py-2 border-b text-left">Type</th>
            <th className="px-4 py-2 border-b text-left">Order</th>
            <th className="px-4 py-2 border-b text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSolutions.map((solution) => (
            <tr key={solution.id} className="hover:bg-gray-50">
              <td className="px-4 py-2 border-b">{solution.name}</td>
              <td className="px-4 py-2 border-b">{solution.code}</td>
              <td className="px-4 py-2 border-b">{solution.credits}</td>
              <td className="px-4 py-2 border-b">{solution.type}</td>
              <td className="px-4 py-2 border-b">{solution.order_no}</td>
              <td className="px-4 py-2 border-b flex">
                <button className="text-yellow-500  p-1 rounded mr-2 hover:bg-yellow-600 hover:text-white flex items-center">
                  <Link to={`/admin/edit-solution/${solution.id}`}>
                    <PencilSquareIcon className="h-5 w-5" />
                  </Link>
                </button>
                <button
                  className="text-red-500 p-1 rounded hover:bg-red-600 hover:text-white flex items-center"
                  onClick={() => handleDeleteClick(solution)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p>
              Do you really want to delete the solution{" "}
              <strong>{solutionToDelete.name}</strong>? This action cannot be
              undone.
            </p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeleteConfirm}
                disabled={buttonLoading}
              >
                {buttonLoading ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Notification Popup */}
      {showNotification && (
        <Notification
          setShowNotification={setShowNotification}
          message={"Solution deleted successfully!"}
        />
      )}
    </div>
  );
};

export default Solution;
