import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import ErrorAlert from "../components/ErrorAlert";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = { email, password };
    setIsLoading(true);

    try {
      const response = await axiosInstance.post("/login", loginData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response;

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Login failed!");
      }

      login(data.token, navigate);
    } catch (error) {
      setError(error.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex bg-white dark:bg-gray-900">
      <div
        className="w-2/3 bg-cover bg-center"
        style={{
          backgroundImage:
            'url("https://wallpapers.com/images/hd/1920x1080-hd-car-bvswz2skd2mquyjc.jpg")',
        }}
      ></div>

      <div className="w-2/3 flex items-center justify-center">
        <div className="w-full max-w-md px-8 py-10 bg-white shadow-lg rounded-lg dark:bg-gray-800">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-red-600">VDP Tuning Team</h1>
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-800 dark:text-white mb-4">
            Welcome
          </h2>

          {error && <ErrorAlert errorMessage={error} />}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
            </div>
            <div className="text-right">
              <Link
                to="/forgot-password"
                className="text-sm text-red-600 hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
            <div>
              <button
                className={`w-full py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "LOGIN"}
              </button>
            </div>
          </form>

          <div className="mt-6 text-center">
            <Link to="/register">
              <button className="w-full py-2 bg-gray-200 text-gray-600 font-bold rounded-md hover:bg-gray-300">
                CREATE NEW ACCOUNT
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
