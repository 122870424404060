const Support = () => {
  return (
    <div>
      <h2 className="text-2xl mb-4">Support</h2>
      <p>If you have any questions, please contact support@example.com.</p>
    </div>
  );
};

export default Support;
