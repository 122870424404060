import { useState } from "react";
import { FaSun, FaMoon } from "react-icons/fa";

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl mb-6 text-gray-900 dark:text-gray-100">
        Account Settings
      </h1>

      {/* Tabs */}
      <div className="flex border-b mb-6 border-gray-300 dark:border-gray-700">
        <button
          className={`mr-4 pb-2 text-gray-900 dark:text-gray-100 ${
            activeTab === "personal" ? "border-b-2 border-blue-500" : ""
          }`}
          onClick={() => setActiveTab("personal")}
        >
          Personal Information
        </button>
        <button
          className={`mr-4 pb-2 text-gray-900 dark:text-gray-100 ${
            activeTab === "preferences" ? "border-b-2 border-blue-500" : ""
          }`}
          onClick={() => setActiveTab("preferences")}
        >
          Preferences
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "personal" && (
        <div>
          <h2 className="text-xl mb-4 text-gray-900 dark:text-gray-100">
            Edit Personal Information
          </h2>
          <form>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                Name
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
                Email
              </label>
              <input
                type="email"
                className="w-full p-2 border rounded border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                placeholder="Enter your email"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-400"
            >
              Save Changes
            </button>
          </form>
        </div>
      )}

      {activeTab === "preferences" && (
        <div>
          <h2 className="text-xl mb-4 text-gray-900 dark:text-gray-100">
            Preferences
          </h2>

          {/* Dark Mode Toggle */}
          <div className="flex items-center space-x-2">
            <span className="text-gray-900 dark:text-gray-100">Dark Mode</span>
            <button
              onClick={toggleDarkMode}
              className="focus:outline-none p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors"
            >
              {isDarkMode ? (
                <FaSun className="text-yellow-400 w-5 h-5" />
              ) : (
                <FaMoon className="text-gray-800 dark:text-gray-200 w-5 h-5" />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
