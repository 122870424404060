import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import { getStatusBadgeClass } from "../utils/Utils";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import DownloadFileButton from "../components/DownloadFileButton";
import DownloadModFileButton from "../components/DownloadModFileButton";
import CancelFileButton from "../components/CancelFileButton";
import SupportChatBox from "./SupportChatBox";

const FileDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/files/${id}`);
        setData(response.data);
      } catch (err) {
        setError("Failed to fetch files");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  if (error) return <div>Error: {error.message}</div>;

  const { file, vehicle } = data;

  return (
    <div className="p-6 min-h-screen bg-gray-100 dark:bg-gray-900">
      {/* Container for File Details and Vehicle Details */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* File Information Section */}
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">File Information</h2>
          <div className="space-y-2">
            <p>
              <strong>File ID:</strong> {file.file_id}
            </p>
            <p>
              <strong>File Name:</strong> {file.file_name}
            </p>
            <p>
              <strong>Price:</strong>{" "}
              <span className="text-yellow-300">CRS {file.price}</span>
            </p>
            <p>
              <strong>Solution:</strong> {file.solution}
            </p>
            <p>
              <strong>Expected:</strong> {file.expected}
            </p>
            <p>
              <strong>Reading Device:</strong> {file.reading_device}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                  file.status
                )}`}
              >
                {file.status}
              </span>
            </p>
            <p>
              <strong>Uploaded:</strong> {file.created_at}
            </p>
            <p>
              <strong>Modified:</strong> {file.updated_at}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">Files</h2>

          <DownloadFileButton fileId={file.id} fileName={file.file_name} />

          <DownloadModFileButton
            fileId={file.id}
            fileName={file.mod_file_name}
            status={file.status}
          />

          {file.notes && (
            <div
              className="items-center bg-red-50 dark:bg-red-900 border border-red-300 dark:border-red-600 text-red-800 dark:text-red-200 text-sm rounded-lg p-4 mt-8"
              role="alert"
            >
              <div className="flex">
                <svg
                  className="w-5 h-5 mr-2 text-red-600 dark:text-red-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.721-1.36 3.486 0l7.092 12.614c.75 1.337-.197 2.887-1.743 2.887H2.908c-1.546 0-2.493-1.55-1.743-2.887L8.257 3.1zM11 14a1 1 0 10-2 0 1 1 0 002 0zm-1-8a1 1 0 00-.993.883L9 7v4a1 1 0 001.993.117L11 11V7a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="font-bold">IMPORTANT</span>
              </div>
              <p>{file.notes}</p>
            </div>
          )}

          <CancelFileButton
            fileId={file.id}
            status={file.status}
            credits={file.price}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Vehicle Information Section */}
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">Vehicle Information</h2>
          <div className="space-y-2">
            <p>
              <strong>Type:</strong> {vehicle.type}
            </p>
            <p>
              <strong>Brand:</strong> {vehicle.brand}
            </p>
            <p>
              <strong>Model:</strong> {vehicle.model}
            </p>
            <p>
              <strong>ECU:</strong> {vehicle.ecu}
            </p>
            <p>
              <strong>Generation:</strong> {vehicle.generation}
            </p>
            <p>
              <strong>Gear:</strong> {vehicle.gear}
            </p>
            <p>
              <strong>Petrol Type:</strong> {vehicle.petrol_type}
            </p>
          </div>
        </div>
      </div>
      {file && file.status === "COMPLETED" && <SupportChatBox />}
    </div>
  );
};

export default FileDetails;
