export const getStatusBadgeClass = (status) => {
  switch (status) {
    case "ACTIVE":
    case "COMPLETED":
      return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300";
    case "DEACTIVE":
    case "CANCELED":
      return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
    case "PENDING":
    case "IN_PROGRESS":
      return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300";
    default:
      return "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300";
  }
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};
