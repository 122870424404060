import React, {
  createContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { decodeJWT } from "../utils/JwtDecode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  // Dark mode state
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // Effect to toggle dark mode class
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  // Auth logic
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    if (storedToken && storedUser) {
      try {
        setToken(storedToken);
        setUser(JSON.parse(storedUser));
        setIsAuthenticated(true);
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    }
    setLoading(false);
  }, []);

  const login = (token, navigate) => {
    const decodedToken = decodeJWT(token);

    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("user", decodedToken.sub);
    setUser(JSON.parse(decodedToken.sub));
    setIsAuthenticated(true);

    const currentUser = JSON.parse(decodedToken.sub);
    if (currentUser.role === "admin") {
      navigate("/admin");
    } else if (currentUser.role === "customer") {
      navigate("/");
    }
  };

  const register = (newUser, navigate) => {
    const { email, username, phone, country, password } = newUser;

    if (email && password && username && phone && country) {
      setUser({ email, username, phone, country });
      setIsAuthenticated(true);
      navigate("/");
    } else {
      alert("Registration failed. Please fill in all the required fields.");
    }
  };

  const logout = (navigate) => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        token,
        loading,
        login,
        register,
        logout,
        isDarkMode,
        setIsDarkMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
