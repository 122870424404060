import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";

const Login = () => {
  const [darkMode, setDarkMode] = useState(false);
  const { login } = useContext(AuthContext); // Access login function from AuthContext
  const [email, setEmail] = useState("");
  const navigate = useNavigate(); // Get navigate function from useNavigate

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={`min-h-screen flex bg-white dark:bg-gray-900`}>
      {/* Left Section - Wallpaper */}
      <div
        className="w-2/3 bg-cover bg-center"
        style={{
          backgroundImage:
            'url("https://wallpapers.com/images/hd/1920x1080-hd-car-bvswz2skd2mquyjc.jpg")',
        }}
      >
        {/* You can replace the URL with the actual image URL */}
      </div>

      {/* Right Section - Login Form */}
      <div className="w-2/3 flex items-center justify-center">
        <div className="w-full max-w-md px-8 py-10 bg-white shadow-lg rounded-lg dark:bg-gray-800">
          {/* Language Toggle */}
          <div className="flex justify-end mb-4">
            <div className="flex items-center space-x-1">
              <img
                src="https://flagcdn.com/us.svg"
                alt="US Flag"
                className="w-5 h-5"
              />
              <span className="text-sm font-medium dark:text-gray-400">EN</span>
            </div>
          </div>

          {/* Logo */}
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-red-600">VDP Tuning Team</h1>
          </div>
          <p className="text-sm text-center text-gray-600 dark:text-gray-400 mb-6">
            Please enter your email to search for your account.
          </p>

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
              />
            </div>
            <div>
              <button className="w-full py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600">
                Reset Password
              </button>
            </div>
          </form>

          <div className="mt-4 text-center">
            <p className="text-gray-600 dark:text-gray-400">
              Back to{" "}
              <Link to={`/login`} className="text-red-600 hover:underline">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
