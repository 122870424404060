import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

const AllUsers = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get("/users");
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch users");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Open delete confirmation modal
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  // Perform delete action
  const handleDeleteConfirm = async () => {
    try {
      await axiosInstance.delete(`/users/${userToDelete.id}`); // API DELETE request by user ID
      setUsers(users.filter((user) => user.id !== userToDelete.id)); // Remove user from state
      setShowDeleteModal(false); // Close modal
      setUserToDelete(null); // Clear selected user
    } catch (error) {
      setError("Failed to delete user");
    }
  };

  // Close the modal without deleting
  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setUserToDelete(null);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "ACTIVE":
        return "bg-green-100 text-green-800";
      case "DEACTIVE":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  // Filtered users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">All Users</h1>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          className="border border-gray-300 p-2 w-full rounded-md"
          placeholder="Search by username, email, role, or status..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Table for displaying users */}
      <table className="min-w-full table-auto bg-white border border-gray-300 rounded-md">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border-b text-left">Username</th>
            <th className="px-4 py-2 border-b text-left">Email</th>
            <th className="px-4 py-2 border-b text-left">Phone</th>
            <th className="px-4 py-2 border-b text-left">Country</th>
            <th className="px-4 py-2 border-b text-left">Credit Balance</th>
            <th className="px-4 py-2 border-b text-left">Role</th>
            <th className="px-4 py-2 border-b text-left">Status</th>
            <th className="px-4 py-2 border-b text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id} className="hover:bg-gray-50">
              <td className="px-4 py-2 border-b">{user.username}</td>
              <td className="px-4 py-2 border-b">{user.email}</td>
              <td className="px-4 py-2 border-b">{user.phone}</td>
              <td className="px-4 py-2 border-b">{user.country}</td>
              <td className="px-4 py-2 border-b">{user.credit_balance}</td>
              <td className="px-4 py-2 border-b">{user.role}</td>
              <td className="px-4 py-2 border-b border-gray-300 dark:border-gray-700">
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                    user.status
                  )}`}
                >
                  {user.status}
                </span>
              </td>
              <td className="px-4 py-2 border-b flex">
                <button className="text-yellow-500  p-1 rounded mr-2 hover:bg-yellow-600 hover:text-white flex items-center">
                  <Link to={`/admin/edit-user/${user.id}`}>
                    <PencilSquareIcon className="h-5 w-5" />
                  </Link>
                </button>
                <button
                  className="text-red-500  p-1 rounded hover:bg-red-600 hover:text-white flex items-center"
                  onClick={() => handleDeleteClick(user)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p>
              Do you really want to delete the user{" "}
              <strong>{userToDelete.username}</strong>? This action cannot be
              undone.
            </p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllUsers;
