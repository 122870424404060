import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import axiosInstance from "../../utils/axiosInstance";
import { truncateText } from "../../utils/Utils";

const FileHistory = ({ fileId }) => {
  const [fileHistory, setFileHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/files/${fileId}/history`);
        setFileHistory(response.data);
      } catch (err) {
        setError("Failed to fetch files");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // Show error message if fetching data fails
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold mb-6">File History</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-200 px-4 py-3 text-left text-sm font-semibold text-gray-700 uppercase">
                File Name
              </th>
              <th className="border-b-2 border-gray-200 px-4 py-3 text-left text-sm font-semibold text-gray-700 uppercase">
                Uploaded
              </th>
            </tr>
          </thead>
          <tbody>
            {fileHistory.map((file) => (
              <tr key={file.id} className="hover:bg-gray-100">
                <td
                  className="border-b border-gray-200 px-4 py-3 text-gray-700"
                  title={file.file_name}
                >
                  {truncateText(file.file_name, 30)}
                </td>
                <td className="border-b border-gray-200 px-4 py-3 text-gray-700">
                  {file.created_at}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FileHistory;
