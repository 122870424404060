const MyTicket = () => {
  return (
    <div>
      <h2 className="text-2xl mb-4">My Tickets</h2>
      <p>List of all your support tickets...</p>
    </div>
  );
};

export default MyTicket;
