const CreateSupport = () => {
  return (
    <div>
      <h2 className="text-2xl mb-4">Create Support Ticket</h2>
      <p>Form to create a new support ticket...</p>
    </div>
  );
};

export default CreateSupport;
