import React from "react";

const SuccessModal = () => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex items-center justify-center">
      <div className="bg-gradient-to-r from-green-400 to-green-500 p-8 rounded-lg shadow-xl transform transition duration-500 scale-105 text-center">
        <div className="bg-white p-4 rounded-full mx-auto w-24 h-24 flex items-center justify-center shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-green-500 animate-bounce"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 5.707 8.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h3 className="mt-6 text-2xl font-extrabold text-white">Success!</h3>
        <p className="text-white mt-2 text-lg">
          Your file has been uploaded successfully.
        </p>
      </div>
    </div>
  );
};

export default SuccessModal;
