import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

const DownloadFileButton = ({ fileId, status, credits }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCancel = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(`/files/${fileId}/cancel`);
      setLoading(false);
      const storedUser = localStorage.getItem("user");
      const user = JSON.parse(storedUser);
      user.credit_balance += credits;
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/my-files");
    } catch (error) {
      console.error("Error while downloading the file:", error);
    }
  };

  return (
    <div className="mt-6">
      {status == "PENDING" || status == "IN_PROGRESS" ? (
        <button
          onClick={handleCancel}
          disabled={loading}
          className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 4a2 2 0 012-2h10a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4zm5 4a1 1 0 000 2h4a1 1 0 000-2H8z"
              clipRule="evenodd"
            />
          </svg>
          {loading ? "Canceling..." : "Click here to cancel"}
        </button>
      ) : (
        (status === "CANCELED" || status === "REFUNDED") && (
          <p className="text-red-300 mt-2">
            Your file is already canceled and you are refunded
          </p>
        )
      )}
    </div>
  );
};

export default DownloadFileButton;
